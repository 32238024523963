<template>
  <div class="dashboard">
    <dashboard-info-block />
    <div class="row row-equal row-gap">
      <div class="flex xs12 md4 xl3">
        <dashboard-resume-list/>
      </div>
      <div class="flex xs12 md8 xl9">
        <dashboard-goals-charts />
      </div>
    </div>
    <div class="row row-equal row-gap">
      <div class="flex xs12">
        <dashboard-charts-Mult/>
      </div>
    </div>
    <div class="row row-equal">
      <div class="flex xs12 md6 xl7">
        <dashboard-map-chart />
      </div>
      <div class="flex xs12 md6 xl5">
        <dashboard-investment-bars />
      </div>
    </div>
    <div class="row row-equal">
      <div class="flex xs12 md12 xl5">
        <dashboard-teams-noequipments />
      </div>
    </div>
    <!--     <va-modal
      ref="newsModal"
      v-model="newsModal"
      :max-width="'100vw'"
      :hide-default-actions="true"
      :fullscreen="false"
      @cancel="closeNewsModal"
    >
      <template v-slot:header>
        <h3 class="mb-4" :style="{color: $themes.primary}"><i class="entypo entypo-megaphone mr-3"></i> {{ $t('layout.modals.whats_new', { version: appVersion }) }}</h3>
      </template>
      <news-modal />
    </va-modal> -->
  </div>
</template>

<script>
import { version } from '../../../package.json'
const DashboardInfoBlock = () => import(/* webpackPrefetch: true */ './DashboardInfoBlock')
const DashboardResumeList = () => import(/* webpackPrefetch: true */ './DashboardResumeList')
const DashboardGoalsCharts = () => import(/* webpackPrefetch: true */ './DashboardGoalsCharts')
const DashboardMapChart = () => import(/* webpackPrefetch: true */ './DashboardMapChart')
const DashboardInvestmentBars = () => import(/* webpackPrefetch: true */ './DashboardInvestmentBars')
const DashboardTeamsNoequipments = () => import(/* webpackPrefetch: true */ './DashboardTeamsNoequipments')
const DashboardChartsMult = () => import(/* webpackPrefetch: true */ './DashboardChartsMult')
// const NewsModal = () => import(/* webpackPrefetch: true */ '@/components/extras/NewsModal')

export default {
  name: 'dashboard',
  components: {
    /* NewsModal, */
    DashboardInfoBlock,
    DashboardResumeList,
    DashboardGoalsCharts,
    DashboardMapChart,
    DashboardInvestmentBars,
    DashboardTeamsNoequipments,
    DashboardChartsMult,
  },
  data () {
    return {
      newsModal: false,
    }
  },
  computed: {
    appVersion: () => version,
  },
  mounted () {
    this.checkNews()
  },
  methods: {
    checkNews () {
      const currentVersion = localStorage.getItem('version')
      if (currentVersion === this.appVersion) return

      this.newsModal = true
    },
    closeNewsModal () {
      this.newsModal = false
      localStorage.setItem('version', this.appVersion)
    },
  },
}
</script>

<style lang="scss">
.row-equal .flex {
  .va-card {
    height: 100%;
  }
}

.dashboard {
  .va-card {
    margin-bottom: 0 !important;
  }
}
</style>
